.slideimage{
    width: 100%;
    height: 500px;
}


/* .carousel-item {
    height: 350px;
} */
.carousel-caption{
    color: #fafafa;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: rgb(216, 207, 207);
    margin: 0px .5vmax;
}

.carousel-caption{
    width: 100vw;
    position: absolute;
    left: 0;
    text-align: left;
    padding-left: 8vmax;
}
.carousel-caption h1{
font-size: 3vmax;
}
.carousel-caption p{
    font-size: 1.5vmax;
    width: 70vw;
    }

    .jj{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        padding: 2vmx;
        /* border: 1px solid white; */

    }
    @media screen and (max-width:768px){
        .jj{
            height: 20px;
            width: 20px;
    
        }
        .slideimage{
            width: 100%;
            height: 300px;
        }
    }

    @media screen and (max-width:500px){
        .jj{
            height: 20px;
            width: 20px;
    
        }
        .slideimage{
            width: 100%;
            height: 250px;
        }
    }
    
    